<template>
    <div class="flex print:block print:pl-2" v-loading="$waiting.is('loading')">
        <div class="relative hidden print:block text-center mb-5 pb-4 border-b">
            <p class="absolute top-0 right-0 text-xs">utskriven {{ todayDate }}</p>
            <p>{{ tableData.heading1 }}</p>
            <p>{{ tableData.heading2 }}</p>
        </div>
        <div class="card-tail sm:w-1/2 print:sm:w-full">
            <div class="flex justify-end text-right text-xs">
                <div class="w-[160px]">
                    <p class="mr-11">
                        {{ formatDate(tableData.period.startDate) }}
                    </p>
                    <div class="border-b -rotate-12 ml-12" />
                    <p>
                        {{ formatDate(tableData.period.endDate) }}
                    </p>
                    <p class="font-semibold">Period</p>
                </div>
                <div class="w-[160px]">
                    <p class="mr-11">
                        {{ formatDate(tableData.accumulated.startDate) }}
                    </p>
                    <div class="border-b -rotate-12 ml-12" />
                    <p>
                        {{ formatDate(tableData.accumulated.endDate) }}
                    </p>
                    <p class="font-semibold">Accumulated</p>
                </div>
                <div class="w-[160px]">
                    <p class="mr-11">
                        {{ formatDate(tableData.previousPeriod.startDate) }}
                    </p>
                    <div class="border-b -rotate-12 ml-12" />
                    <p>
                        {{ formatDate(tableData.previousPeriod.endDate) }}
                    </p>
                    <p class="font-semibold">Previous</p>
                </div>
            </div>
            <table class="w-full">
                <tr v-for="item in tableData.row" :key="item.account" :class="{ 'hover:bg-gray-100': item.type === 4 }" @click="rowClicked(item)" class="">
                    <td>
                        <p class="w-14 text-sm print:text-xs">{{ item.account }}</p>
                    </td>
                    <td>
                        <p
                            class="flex-1 print:text-xs"
                            :class="{
                                'font-bold': item.type === 1,
                                'font-semibold': item.type === 5 || item.type === 2,
                                'text-sm': item.type === 4,
                                'mb-4': item.type === 3,
                            }"
                        >
                            {{ item.name }}
                        </p>
                    </td>
                    <td width="160">
                        <p
                            class="text-sm print:text-xs text-right"
                            :class="{
                                'font-semibold': item.type === 5,
                            }"
                        >
                            {{ item.period | swedishNumberFormat }}
                        </p>
                    </td>
                    <td width="160">
                        <p
                            class="text-sm print:text-xs text-right"
                            :class="{
                                'font-semibold': item.type === 5,
                            }"
                        >
                            {{ item.accumulated | swedishNumberFormat }}
                        </p>
                    </td>
                    <td width="160">
                        <p
                            class="text-sm print:text-xs text-right"
                            :class="{
                                'font-semibold': item.type === 5,
                            }"
                        >
                            {{ item.previousPeriod | swedishNumberFormat }}
                        </p>
                    </td>
                </tr>
            </table>
        </div>
        <div class="w-1/2 ml-3 print:hidden">
            <AccountDetails ref="accountDetailsView" :client-id="clientId" :account-number="selectedAccount" :selected-date="[query.startDate, query.endDate]" />
        </div>
    </div>
</template>
<script>
import moment from "moment";
import Api from "../lopande.api";

export default {
    data() {
        return {
            tableData: {
                period: {
                    startDate: null,
                    endDate: null,
                },
                accumulated: {
                    startDate: null,
                    endDate: null,
                },
                previousPeriod: {
                    startDate: null,
                    endDate: null,
                },
            },
            selectedAccount: null,
        };
    },

    props: {
        query: {
            type: Object,
            default: () => {},
        },
        clientId: {
            type: String,
            default: "",
        },
    },

    components: {
        AccountDetails: () => import("./ResultatrakningAccountDetails"),
    },

    watch: {
        query() {
            this.getAll();
        },
    },

    computed: {
        todayDate() {
            return moment().format("YYYY-MM-DD");
        },
    },

    created() {
        this.getAll();
    },

    methods: {
        async getAll() {
            this.$waiting.start("loading");
            try {
                const { startDate, endDate } = this.query;
                let data = await Api.getResultatrakning({ clientId: this.clientId, startDate: startDate, endDate: endDate });
                this.tableData = Object.freeze(data);
                if (this.$refs.accountDetailsView) this.$refs.accountDetailsView.resetState();
                this.selectedAccount = null;
            } finally {
                this.$waiting.end("loading");
            }
        },

        formatDate(date) {
            if (!date) return;
            return moment(date).format("YYYY.MM.DD");
        },

        rowClicked(item) {
            if (!item.account) return;
            this.selectedAccount = item.account;
        },
    },
};
</script>
